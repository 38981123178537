<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12" sm="6" lg="3" v-if="isAccountPermission">
        <base-material-card
          color="primary"
          icon="mdi-cart-variant"
          class="v-card--material-stats"
          v-bind="$attrs"
          v-on="$listeners"
        >
          <template v-slot:after-heading>
            <div class="ml-auto text-right">
              <div
                class="body-3 grey--text font-weight-light"
                v-text="'Vendas'"
              />

              <h3 class="display-3 font-weight-light text--primary">
                {{ dashboardData.totalOrders }}
              </h3>
            </div>
          </template>
        </base-material-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
        v-if="hasAdminPermission"
      >
        <base-material-card
          color="info"
          icon="mdi-account-box-multiple"
          class="v-card--material-stats"
        >
          <template v-slot:after-heading>
            <div class="ml-auto text-right">
              <div
                class="body-3 grey--text font-weight-light"
                v-text="'Grupos de contas'"
              />

              <h3 class="display-3 font-weight-light text--primary">
                {{ dashboardData.totalGroups }}
              </h3>
            </div>
          </template>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
        v-if="hasManageGroupPermission"
      >
        <base-material-card
          color="primary"
          icon="mdi-account-box"
          class="v-card--material-stats"
        >
          <template v-slot:after-heading>
            <div class="ml-auto text-right">
              <div
                class="body-3 grey--text font-weight-light"
                v-text="'Contas'"
              />

              <h3 class="display-3 font-weight-light text--primary">
                {{ dashboardData.totalAccounts }}
              </h3>
            </div>
          </template>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
        v-if="hasManageAccountPermission"
      >
        <base-material-card
          color="success"
          icon="mdi-account"
          class="v-card--material-stats"
        >
          <template v-slot:after-heading>
            <div class="ml-auto text-right">
              <div
                class="body-3 grey--text font-weight-light"
                v-text="'Usuários'"
              />

              <h3 class="display-3 font-weight-light text--primary">
                {{ dashboardData.totalUsers }}
              </h3>
            </div>
          </template>
        </base-material-card>
      </v-col>
    </v-row>

    <v-row v-if="isAccountPermission">
      <v-col
        cols="12"
        lg="2"
      >
        <base-material-card
          color="primary"
          icon="mdi-glasses"
          class="v-card--material-stats"
          v-bind="$attrs"
          v-on="$listeners"
        >
          <template v-slot:after-heading>
            <div class="ml-auto text-right">
              <div
                class="body-3 grey--text font-weight-light"
                v-text="'Lentes'"
              />

              <h3 class="display-3 font-weight-light text--primary">
                {{ dashboardData.productsData.totalLentes }}
              </h3>
            </div>
          </template>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        lg="2"
      >
        <base-material-card
          color="primary"
          icon="mdi-glasses"
          class="v-card--material-stats"
          v-bind="$attrs"
          v-on="$listeners"
        >
          <template v-slot:after-heading>
            <div class="ml-auto text-right">
              <div
                class="body-3 grey--text font-weight-light"
                v-text="'Armações'"
              />

              <h3 class="display-3 font-weight-light text--primary">
                {{ dashboardData.productsData.totalArmacoes }}
              </h3>
            </div>
          </template>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        lg="2"
      >
        <base-material-card
          color="primary"
          icon="mdi-puzzle-plus-outline"
          class="v-card--material-stats"
          v-bind="$attrs"
          v-on="$listeners"
        >
          <template v-slot:after-heading>
            <div class="ml-auto text-right">
              <div
                class="body-3 grey--text font-weight-light"
                v-text="'Acessórios'"
              />

              <h3 class="display-3 font-weight-light text--primary">
                {{ dashboardData.productsData.totalAcessorios }}
              </h3>
            </div>
          </template>
        </base-material-card>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import { DashboardService } from '@/code/services/dashboard/dashboardService';
import { DashboardData } from '@/code/models/dashboard/DashboardData';
import { AuthUtil } from '@/code/util/authUtil';
import { EUserRole } from '@/code/enums/EUserRole';
import { EventBus } from '@/code/util/eventBus';
  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        dashboardData: new DashboardData({}),
        productsDataChart: { data: {}, options: {}, responsiveOptions: [] },
        productsPieDataChart: { data: {} },
        hasAdminPermission: Boolean,
        hasManageGroupPermission: Boolean,
        hasManageAccountPermission: Boolean,
        isAccountPermission: Boolean
      }
    },

    mounted() {
      this.loadDashboardData();
      this.updatePermissions();
      EventBus.$on("currentUserLoaded", this.updatePermissions);
    },

    methods: {
      loadDashboardData: async function() {
        const service = new DashboardService();
        this.dashboardData = await service.loadDashboard();
        this.productsPieDataChart = {
          labels: ['Lentes', 'Armações', 'Acessórios'],
          series: [this.dashboardData.productsData.totalLentes, this.dashboardData.productsData.totalArmacoes, this.dashboardData.productsData.totalAcessorios]
        };
      },
      updatePermissions: function() {
        const authUtil = new AuthUtil();
        const rule = authUtil.getUserRole();
        this.hasAdminPermission = rule === EUserRole.Admin;
        this.hasManageGroupPermission = this.hasAdminPermission || rule === EUserRole.ManageGroup;
        this.hasManageAccountPermission = this.hasManageGroupPermission || rule === EUserRole.ManageAccount;
        this.isAccountPermission = rule === EUserRole.ManageAccount;
      },
    },
  }
</script>
